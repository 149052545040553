<template>
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9963 12.3999C14.1491 12.3999 15.8625 10.6539 15.8625 8.4999C15.8625 6.3459 14.1173 4.5999 11.9963 4.5999C9.84338 4.5999 8.13 6.3459 8.13 8.4999C8.09625 10.6524 9.84375 12.3999 11.9963 12.3999ZM11.9963 6.3999C13.1531 6.3999 14.0948 7.3419 14.0948 8.4999C14.0948 9.6579 13.1512 10.5999 11.9963 10.5999C10.8413 10.5999 9.89625 9.65865 9.89625 8.4999C9.89625 7.34115 10.8375 6.3999 11.9963 6.3999ZM19.2 6.3999C20.8568 6.3999 22.2 5.05665 22.2 3.3999C22.2 1.74315 20.8575 0.399902 19.2 0.399902C17.5433 0.399902 16.2 1.74315 16.2 3.3999C16.2 5.05665 17.5425 6.3999 19.2 6.3999ZM13.8713 13.5999H10.1288C7.185 13.5999 4.8 15.8387 4.8 18.5987C4.8 19.1537 5.27625 19.5999 5.865 19.5999H18.135C18.7238 19.5999 19.2 19.1537 19.2 18.5987C19.2 15.8387 16.815 13.5999 13.8713 13.5999ZM6.67875 17.7999C7.07138 16.4214 8.45475 15.3999 10.0965 15.3999H13.8713C15.5126 15.3999 16.896 16.4214 17.289 17.7999H6.67875ZM20.6963 7.5999H18.3773C17.8973 7.5999 17.4443 7.71379 17.0325 7.9089C17.055 8.10615 17.0925 8.2974 17.0925 8.4999C17.0925 9.76403 16.6133 10.9078 15.849 11.7999H23.3378C23.7038 11.7999 24 11.4849 24 11.0987C24 9.1674 22.5225 7.5999 20.6963 7.5999ZM6.89625 8.4999C6.89625 8.29557 6.93309 8.10128 6.95659 7.90328C6.5475 7.67865 6.0975 7.5999 5.62125 7.5999H3.303C1.479 7.5999 0 9.1674 0 11.0987C0 11.4849 0.295763 11.7999 0.66075 11.7999H8.142C7.37625 10.9074 6.89625 9.76365 6.89625 8.4999ZM4.8 6.3999C6.45675 6.3999 7.8 5.05665 7.8 3.3999C7.8 1.74315 6.4575 0.399902 4.8 0.399902C3.14325 0.399902 1.8 1.74315 1.8 3.3999C1.8 5.05665 3.14325 6.3999 4.8 6.3999Z"
      fill="#191617"
    />
  </svg>
</template>
